.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-height: 100% !important;
  overflow: auto;
}

html, 
body {
    height: 100%;
    overflow: auto;
}

body::-webkit-scrollbar {
  display: none;
}

.appBar {
  background-color: var(--accent);
  padding: 15;
  display: "flex";
  flex-direction: "row";
  justify-content: "space-between";
  align-items: "center";
}

.loadingContainer,
.loading {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginError {
  color:#EC4252;
  text-align: right;
  font-size: 15px;
}


.DeleteAccountContent {
  display: flex;
  flex-grow: 1;
  max-width: 700px;
  flex-direction: column;
  /* max-height: 500px; */
  /* padding: 50px 30px 50px 30px; */
}

.passwordLabel {
  color: hsl(0, 0%, 50%) !important;
  padding-right: 20px !important;
}

.sectionTitle {
  color: var(--primary);
  font-size: 20px;
}


.logoutDrawer {
  font-size: 18px;
  margin-left: 10px;
}


.notFoundText {
  margin: 30px 0;
}

.linkButton {
  min-width: 25ch;
  padding: 22.5px 30px;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  border-radius: 10px;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  padding: 15px 15px 0;
}

.logoIcon {
  min-width: 45px;
  max-width: 55px;
  height: 125px;
  object-fit: contain;
  cursor: pointer;
}

.logoName {
  margin-left: 10px;
  max-width: 80px;
  object-fit: contain;
  cursor: pointer;

}

.drawerContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding-bottom: 7px;
  margin-top: 60px;
}


.itemInactive {
  opacity: 0.5;
}

.listIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 18px;
}

.listItemTitle {
  color: #58C2D7;
}

.logout {
  color: #000000;
  font-weight: 900;
}

.listItemTitleActive {
  font-weight: 900;
}

.listItemNestedTitle {
  color: #58C2D7;
}


.toggleDrawerIcon {
  width: 20px;
  height: 20px;
}


.contentContainerWrapper {
  /* max-width: 1100px; */
  width: 100%;
  position: relative;
  margin: 0 auto 30px;
}


.contentContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  padding: 24px 40px;
   margin: 0px  24px 30px;
  box-sizing: border-box;
  flex-shrink: 0;
}


.subHeader{
  display: flex;
flex: 1;
align-items: center;
background-color: #F4F4F4;
padding: 0px 24px;
margin-top: 5px;
}


.subHeader2 {
  display: flex;
align-items: center;
background-color: #F4F4F4;
padding: 0px 24px;
margin-top: 5px;

}

.homeContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}


.divider {
  margin-bottom: 15px !important;
}

@media (max-width:760px) {
  
  .contentContainer{
    padding: 16px;
    margin-inline: 10px;
  }
  
  .sectionTitle{
    font-size: 22px;
  }

  
  .subHeader2 {
  padding: 0px 10px;
  }

   
  .drawerContent {
    margin-top: 30px;
  }
}

@media (max-width:600px) {
  
  .subHeader{
    padding: 0 15px;
  }
}

@media (max-width:760px) {
  
  .subHeader{
    padding: 0 10px;
    z-index: 2;
  }
}

/* Remove Arrow on input  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
